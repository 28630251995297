import request from "@/utils/request";
const controller = "/SchoolInfo";

export function getPageList(query) {
  return request({
    url: `${controller}/GetPageList`,
    method: "get",
    params: query
  });
}

export function getAllList() {
  return request({
    url: `${controller}/GetAllList`,
    method: "get"
  });
}
export function getInfo(id) {
  return request({
    url: `${controller}/GetInfo`,
    method: "get",
    params: {
      id
    }
  });
}
export function createEntity(data) {
  return request({
    url: `${controller}/Insert`,
    method: "post",
    data
  });
}

export function updateEntity(data) {
  return request({
    url: `${controller}/Update`,
    method: "post",
    data
  });
}

export function deleteEntity(id) {
  return request({
    url: `${controller}/Delete`,
    method: "post",
    params: {
      id
    }
  });
}

export function getAttributeOptions() {
  return request({
    url: `${controller}/GetAttributeOptions`,
    method: "post"
  });
}

export function submitInfo(data) {
  return request({
    url: `${controller}/SubmitInfo`,
    method: "post",
    data
  });
}

const getCooperationEnterprise = schoolId =>
  request({
    url: `${controller}/GetCooperationEnterprise`,
    method: "get",
    params: { id: schoolId }
  })

const getStatistics = unitId =>
  request({
    url: `${controller}/GetStatistics`,
    method: "get",
    params: { unitId }
  });

const searchSchoolBaseList = name =>
  request({
    url: `${controller}/SearchSchoolBaseList`,
    method: "get",
    params: { name }
  });

  const getHalfYearAccessTrend = _ =>
  request({
    url: `${controller}/GetHalfYearAccessTrend`,
    method: "get"
  });

export {
  getCooperationEnterprise,
  getStatistics,
  searchSchoolBaseList,
  getHalfYearAccessTrend
}
